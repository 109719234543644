<script lang="ts">
    import InfoBox from '../Common/InfoBox.svelte';
    import TokenInput from '../Common/TokenInput.svelte';
    import PaymentMethodButton from './PaymentMethodButton.svelte';
    import TransactionSettings from './TransactionSettings.svelte';
    import PaymentMethods from './PaymentMethods.svelte';
    import type { IChain } from '../../Data/Chains';
    import { Chains } from '../../Data/Chains';
    import { paymentMethods, paymentMethodsMap, SkipShiftContracts, LastScannedTimestamp } from '../../Data/P2P';
    import type { IP2PTradeLiq, IP2PTrade, IUserInfo, IRawListing, IPaymentMethod } from '../../Data/P2P';
    import { AllLiquidityStore, Collateral, P2PCache } from '../../Data/P2PStore';
    import { getUserLiqList, getP2pTrades, getAddressInfo, fetchActiveLiq, preFetchCollateral, fetchLatestTradeTimestamp } from '../../API/P2P';
    import TradeList from './TradeList.svelte';
    import TokenList from '../Common/TokenList.svelte';
    import type { IToken } from '../../Data/Tokens';
    import BigNumber from 'bignumber.js';
    import { onDestroy, onMount } from 'svelte';
    import { Wallet, WalletConnected, ChainId, CryptKey, WalletVerified } from '../../Data/Wallet';
    import { initXmtp, isWallet } from '../../API/Chat';
    import { connect } from '../../API/Wallet';
    import { ShowActionLogs, addActionLogLine } from '../../Data/ActionLogs';
    import { createDataObjects, type IChainBit } from '../../API/helpers/listingParser';
    import type { IQuoteResult } from '../../Data/QuoteResult';
    import { getPriceQuote } from '../../API/QuoteEngine';
    import type { IProviderRate, IP2PVenue } from '../../Data/P2P';
    import { calculateRampPrices, collateralNeeded, createP2pTrade } from '../../API/P2P';
    import { getIndexBreadthFirst } from '../../API/helpers/utils';
    import TradeDetails from './TradeDetails.svelte';
    import subZero from '../../API/helpers/subZero';
    import { SkipTexts, Locale, ActionLogTexts } from '../../Data/Locale';
    import { debounce } from '../../API/utils/debounce';
    import { CurrencyRates } from '../../Data/CurrencyRates';
    import autoAnimate from '../../API/helpers/autoAnimate';
    import { P2PDataStore } from '../../Data/P2PDataStore';
    import Contracts from '../../Data/Contracts';
    import { TxError } from '../../Data/TxError';
    import { formatError } from '../../API/helpers/formatErrors';
    import { Result, getMinMax } from '../../API/utils/aggregateMinMax';
    import { hexToRgba } from '../../API/helpers/hexToRGBA';
    import chainSwitcher from '../../API/helpers/chainSwitcher';
    import LiqList from './LiqList.svelte';
    import AddLiquidity from './AddLiquidity.svelte';
    import isObjectEmpty from '../../API/utils/isObjectEmpty';
    import AdvancedLiquidityList from '../../components/Common/AdvancedLiquidityList.svelte';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    import { fetchProviderHours } from '../../API/helpers/fetchProviderHours';
    import IdInputs from './IdInputs.svelte';
    import { PrefillData } from '../../Data/Prefill';
    import { validatePrefill } from '../../API/utils/validatePrefill';
    import { getSpreadRange } from '../../API/helpers/getSpreadRange';
    import WalletProviders from '../Utils/WalletProviders.svelte';
    import { isMobile } from '../../API/utils/checkMobile';

    BigNumber.set({ DECIMAL_PLACES: 18, EXPONENTIAL_AT: 100 });

    export let inWidget: boolean = false;

    let showWallets: boolean;

    interface DataObject {
        venues: Record<string, IChainBit>;
        assets: IToken[];
        currencies: IToken[];
    }

    type PaymentDetail = [string, string]; // Represents [service, transactionId]

    type CurrencyExchangeArray = [
        string, // cryptocurrency
        string, // fiatCurrency
        PaymentDetail[], // Array of payment methods
        '0' | '1' // active (as a string for "0" or "1")
    ];

    type ExchangeListArray = CurrencyExchangeArray[];

    // **** START NEW VARS FOR LISTREDUX
    let itemList0: IToken[] = [];
    let itemList1: IToken[] = [];
    let itemBuyList0: IToken[] = []; // fiat if BUY, tokens if SELL
    let itemBuyList1: IToken[] = []; // fiat if BUY, tokens if SELL
    let itemSellList0: IToken[] = []; // tokens if BUY, fiat if SELL
    let itemSellList1: IToken[] = []; // tokens if BUY, fiat if SELL
    let venuesList: IChain[] = [];
    let fiatTokenPairs: ExchangeListArray = [];
    let allLiquidityData: {
        returnData: IRawListing[];
        minMax: Result;
        pairs: any[][];
        timestamp: number;
    } = {} as any;
    let dataObjectAll: DataObject;
    let dataObjectBUY: DataObject;
    let dataObjectSELL: DataObject;
    let streamUpdates: boolean = false;
    // **** END NEW VARS FOR LISTREDUX

    let updateInterval = 45000;

    let showTransactionSettings: boolean = false;
    let showPaymentMethods: boolean = true;
    let showToken0List: boolean = false;
    let showToken1List: boolean = false;
    let showErrors: boolean = false;
    let showTradeLockedWarning: boolean = false;
    let showMoreRates: boolean = false;
    let loadingItems = false;
    let loadingUserListings = false;
    let arbitrationEnabled = false;
    let priceLoader: boolean = false;
    let tradeReady: boolean = false;
    let noLiquidity = true;
    let inPrompt: boolean = false;
    let showMinMaxNotice = false;
    let min: BigNumber = new BigNumber(0);
    let max: BigNumber = new BigNumber(0);

    let chatTarget: string;

    let rate: BigNumber;
    let rampRate: IProviderRate = {
        spread: 0,
        totalAmount: new BigNumber(0),
        maker: '',
        venue: [],
        listingId: 0,
        min: BigNumber(0),
        max: BigNumber(0),
        kypString: ''
    };
    let otherRampRates: IProviderRate[] = [];
    let spreadRange: string;
    let kypInputFields: string[] = [];
    let collatPreFetch;
    let collateral: BigNumber = new BigNumber(0);
    let collateralPriceData: IQuoteResult | undefined;
    let matchedVenue: IP2PVenue = {
        platform: '',
        identHash: '',
        userId: '',
        makerId: '',
        takerId: ''
    };
    let providerHours: string | undefined;

    let kypInfoString: string;
    $: console.log('kyp info string', kypInfoString);
    $: console.log('pay id', venueId);

    let updateTimeout: any;

    let token0: IToken | null = null;
    let token1: IToken | null = null;
    let amount0: BigNumber | null = null;
    let amount1: BigNumber | null = null;
    let userInfo: IUserInfo | undefined;
    let minMaxSymbol: string = '';

    let order: IP2PTradeLiq | IP2PTrade;

    let actionTitleTrade = SkipTexts[$Locale].connect_wallet;

    let userLiquidityListings: IP2PTradeLiq[] | undefined = [];
    let trades: IP2PTradeLiq[] = [];
    let selectedVenue: IPaymentMethod | null = null;
    let venueId: string;
    let selectedMenuItem: 'BUY' | 'SELL' | 'LIST' | 'LIQ' | 'ADD' = 'BUY';
    let chainId: number = 1;
    let enableProviderMode: boolean = false;

    let lastUpdateTime = 0; //performance.now();

    // export let activeMenuTextColor: string = '03a9f4';
    // export let inactiveMenuTextColor: string = 'bae6fd';
    // export let backgroundColor: string = 'FFFFFF';
    // export let borderColor: string = '03a9f4';
    // export let buttonGradient: string = '0ea5e9';
    // export let inputBg: string = 'F2F2F2';
    // export let infoText: string = '6b7280';
    // export let headerColor: string = 'f3f4f6';

    $: ($ChainId || $WalletConnected) && getActionTitle();
    //@ts-ignore
    $: ($Wallet || $WalletVerified || $ChainId || $Wallet?.address) && walletChanged();
    $: $P2PDataStore && streamUpdates && step2streamDataUpdates();
    $: $TxError && onErrorReceived();
    $: (token0 || token1) && (() => updateCollateral()) && refreshMinMax();
    // $: (token0 || token1) && refreshMinMax();
    // $: token1 && refreshMinMax();
    $: if (!amount0) amount1 = null;
    $: $ChainId, onChainSwitch();
    $: $Locale && getActionTitle();
    $: if($P2PDataStore && $P2PDataStore.returnData?.length > 0 && itemList0.length > 0 && itemList1.length > 0){
        updatePrefill();
    }

    const updatePrefill = () => {
        if($PrefillData && !token0 && !token1){
            console.log('updating prefill deets', itemList0, itemList1);
            $USER_SETTINGS.providerMode = false;//override provider mode if prefill present
            selectedMenuItem = $PrefillData.direction == "0" ? "SELL" : "BUY";
            const validate = validatePrefill($PrefillData)
            if(validate.status){
                console.log('prefill validated');
                console.log('prefill selectedMenuItem', selectedMenuItem);
                min = BigNumber(validate.min);
                max = BigNumber(validate.max);
                rampRate.spread = validate.spread || 0;
                if(selectedMenuItem == "BUY"){
                    token0 = itemList0.find((currency) => currency.symbol?.toLowerCase() == $PrefillData.currency.toLowerCase()) || null;
                    // console.log('prefill token0', token0, itemList0);
                    token1 = itemList1.find((token) => token.address?.toLowerCase() == $PrefillData.asset.toLowerCase()) || null;
                }

                if(selectedMenuItem == "SELL"){
                    onSwitchDirectionClick();//simulate clicking the button to update itemLists
                    console.log('prefill in sell', itemList0, $PrefillData);
                    token0 = itemList0.find((token) => token.address?.toLowerCase() == $PrefillData.asset.toLowerCase()) || null;
                    
                    token1 = itemList1.find((currency) => currency.symbol?.toLowerCase() == $PrefillData.currency.toLowerCase()) || null;
                }
                if($PrefillData.venue) selectedVenue = paymentMethods.find((venue) => venue.shortName?.toLowerCase() == $PrefillData.venue?.toLowerCase()) || null;
                //TODO: fix this so that amount can be specified in prefill
                // amount0 = $PrefillData.amount || null;
            }
            console.log('prefill loaded', token0, token1);
        }
    }

    onMount(async () => {
        showWallets = false;
        if (inWidget) console.log('widget load');
        step0getLatestData();
        await getActionTitle();
        const params = new URLSearchParams(window.location.search);
        // console.log('prefill data', $PrefillData);
        arbitrationEnabled = params.get('enable-arbitration') ? true : false;
        $USER_SETTINGS.providerMode = params.get('provider-mode') ? true : $USER_SETTINGS.providerMode;
        updateUserData().then(() => {
            if (inWidget) console.log('widget load');
        });

        if ($USER_SETTINGS.providerMode && !$PrefillData) selectedMenuItem = 'LIQ';
    });

    const walletChanged = async () => {
        if (selectedMenuItem == 'LIST') selectedMenuItem = 'BUY';
        trades = [];
        if ($WalletConnected && SkipShiftContracts[$ChainId]) {
            updateUserData().then(() => {
                requestAnimationFrame(userDataPolling);
            });
        }
    };

    const updateKypInfoString = () => {
        kypInfoString = kypInputFields.join('::');
        getActionTitle();
    };
    const onKypInputChange = (value: string, index: number) => {
        kypInputFields[index] = value;
        updateKypInfoString();
    };

    const updateCollateral = async () => {
        try {
            chainId = (selectedMenuItem === 'BUY'
                        ? token1?.chainId
                        : token0?.chainId) ?? $ChainId;

            const data = await preFetchCollateral(chainId);
            // console.log('new data collateral test', data);
            collatPreFetch = { ...data };
        } catch (err) {
            console.log('collatPreFetch err: ', err);
        }
    };


    const userDataPolling = async () => {
        const currentTime = performance.now();
        // console.log('userDataPolling currentTime: ', currentTime);

        if (lastUpdateTime === 0 || currentTime - lastUpdateTime >= updateInterval) {
            cancelAnimationFrame(updateTimeout);
            updateUserData();
            lastUpdateTime = currentTime;
        }

        updateTimeout = requestAnimationFrame(userDataPolling);
    };

    const updateUserData = async () => {
        chainId = (selectedMenuItem === 'BUY'
                        ? token1?.chainId
                        : token0?.chainId) ?? $ChainId;

        if ($USER_SETTINGS.showInactive && $LastScannedTimestamp > (await fetchLatestTradeTimestamp())) {
            return;
        }
        if (!$WalletConnected || loadingItems) return;

        loadingItems = true;
        getActionTitle();

        if (!(await isWallet())) {
            loadingItems = false;
            getActionTitle();
            return;
        } else {
            trades = [...trades];
        }

        addActionLogLine(ActionLogTexts[$Locale].getting_latest);

        if ($Wallet?.address && $WalletConnected && SkipShiftContracts[$ChainId]) {
            await Promise.allSettled([getTrades(), getUserInfo()]);
        }
        trades = [...trades];

        console.log('updateUserData trades', trades);
        loadingItems = false;
    };

    onDestroy(() => {
        if (updateTimeout) {
            cancelAnimationFrame(updateTimeout);
        }
    });

    const step0getLatestData = () => {
        streamUpdates = true;

        const testing = window.location.href.includes('testing');

        fetchActiveLiq(testing)
            .then((data0) => {
                if (!data0) return;
                streamUpdates = false;
                $AllLiquidityStore = allLiquidityData;
                allLiquidityData = data0 as any;
                dataObjectAll = createDataObjects(data0.returnData);
                fiatTokenPairs = data0.pairs as ExchangeListArray;

                let allLiquidityDataBUY = allLiquidityData.returnData.filter((liq) => liq.type == '1');
                let allLiquidityDataSELL = allLiquidityData.returnData.filter((liq) => liq.type == '0');
                dataObjectBUY = createDataObjects(allLiquidityDataBUY);
                dataObjectSELL = createDataObjects(allLiquidityDataSELL);

                step1assignData();
            })
            .catch((err) => {
                streamUpdates = false;
                console.log('err: ', err);
            })
            .finally(() => {
                getNewTradeRate();
            });
    };

    const onErrorReceived = () => {
        console.log('received error:', $TxError);
        if (!!$TxError && $TxError.length > 0) {
            showErrors = true;
            actionTitleTrade = $TxError;
            setTimeout(() => {
                $TxError = '';
                showErrors = false;
                getActionTitle();
            }, 30000);
        } else showErrors = false && getActionTitle();
    };

    const step1assignData = async (filter: boolean = true) => {
        if (!allLiquidityData || !dataObjectAll || !dataObjectBUY || !dataObjectSELL) return;

        // get all currencies based off allLiquidityData.pairs
        let matchedBuyCurrencies = fiatTokenPairs.filter((pair) => (token0 && filter ? token0?.symbol == pair[1] : true) && pair[3] == '1');
        let matchedSellCurrencies = fiatTokenPairs.filter((pair) => (token0 ? token0?.symbol == pair[0] : true) && pair[3] == '0');

        itemBuyList0 = dataObjectBUY.currencies;//.filter((asset) => matchedBuyCurrencies.some((pair) => pair[1] == asset.symbol));
        itemBuyList1 = dataObjectBUY.assets.filter((asset) =>
            matchedBuyCurrencies.some((pair) => pair[0] == asset.symbol && (token0 && filter ? token0?.symbol == pair[1] : true))
        );
        itemSellList0 = dataObjectSELL.assets;//.filter((asset) => matchedSellCurrencies.some((pair) => pair[0] == asset.symbol));
        itemSellList1 = dataObjectSELL.currencies.filter((asset) =>
            matchedSellCurrencies.some((pair) => pair[1] == asset.symbol && (token0 && filter ? token0?.symbol == pair[0] : true))
        );

        if (selectedMenuItem === 'BUY') [itemList0, itemList1] = [itemBuyList0, itemBuyList1];
        else [itemList0, itemList1] = [itemSellList0, itemSellList1];

        // remove duplicates
        itemList0 = itemList0.filter(
            (item, index, self) => self.findIndex((t) => t.symbol === item.symbol && t.address === item.address && t.chainId === item.chainId) === index
        );
        itemList1 = itemList1.filter(
            (item, index, self) => self.findIndex((t) => t.symbol === item.symbol && t.address === item.address && t.chainId === item.chainId) === index
        );

        if (selectedMenuItem == 'BUY') {
            venuesList = [];
            fiatTokenPairs.forEach((pair) => {
                if (
                    (pair[0] == token1?.symbol && pair[1] == token0?.symbol && pair[3] == '1') ||
                    (pair[0] == token0?.symbol && pair[1] == token1?.symbol && pair[3] == '1')
                ) {
                    Object.values(paymentMethods).forEach((method) => {
                        if (pair[2].some((p) => p.includes(method.shortName || ""))) {
                            if (!venuesList.find((venue) => venue.shortName == method.shortName)) venuesList.push(method);
                        }
                    });
                }
            });
        } else if (selectedMenuItem == 'SELL') {
            venuesList = [];
            fiatTokenPairs.forEach((pair) => {
                if ((pair[0] == token0?.symbol && pair[1] == token1?.symbol) && pair[3] == '0' || (pair[0] == token1?.symbol && pair[1] == token0?.symbol && pair[3] == '0')) {
                    Object.values(paymentMethods).forEach((method) => {
                        if (pair[2].some((p) => p.includes(method.shortName || ""))) {
                            if (!venuesList.find((venue) => venue.shortName == method.shortName)) venuesList.push(method);
                        }
                    });
                }
            });
        }

        // this is used for a specific edge case where the user requotes with a diff asset & diff venues
        if (!selectedVenue || !venuesList.includes(selectedVenue)) {
            selectedVenue = venuesList[0];
        }
    };

    const step2streamDataUpdates = () => {
        if (!streamUpdates || !$P2PDataStore || !$P2PDataStore?.pairs || !$P2PDataStore?.returnData) return;
        allLiquidityData = {
            ...$P2PDataStore,
            minMax: $P2PDataStore.minMax || { min: new BigNumber(0), max: new BigNumber(0) }
        };        
        dataObjectAll = createDataObjects($P2PDataStore.returnData);
        fiatTokenPairs = $P2PDataStore.pairs as ExchangeListArray;

        let allLiquidityDataBUY = allLiquidityData.returnData.filter((liq) => liq.type == '1');
        let allLiquidityDataSELL = allLiquidityData.returnData.filter((liq) => liq.type == '0');
        dataObjectBUY = createDataObjects(allLiquidityDataBUY);
        dataObjectSELL = createDataObjects(allLiquidityDataSELL);

        step1assignData();
    };

    const getNewTradeRateDebounced = async () => {
        if (!amount0 || amount0?.lte(0)) return;
        amount1 = null;
        rampRate = {
            spread: 0,
            totalAmount: new BigNumber(0),
            maker: '',
            venue: [],
            listingId: 0,
            min: BigNumber(0),
            max: BigNumber(0),
            kypString: ''
        };
        debounce(getNewTradeRate, 750)();
    };

    const getNewTradeRate = async () => {
        if (!amount0 || amount0?.lte(0)) return;
        showMinMaxNotice = false;
        providerHours = undefined;
        showErrors = false && getActionTitle();
        if (!token0 || !token1) return;
        if (!amount0 || amount0?.lte(0) || !amount0?.isFinite()) amount0 = BigNumber(1);
        if (!selectedVenue || !venuesList.includes(selectedVenue)) selectedVenue = venuesList[0];

        showTradeLockedWarning = false;
        if (userInfo && +userInfo?.tradeLocked > 0) showTradeLockedWarning = true;

        priceLoader = true;

        chainId = (selectedMenuItem === 'BUY'
                        ? token1?.chainId
                        : token0?.chainId) ?? $ChainId;
        let stable: IToken = $Chains[chainId].stableCoins[0];
        stable.chainId = chainId;
        let quote: IQuoteResult | undefined;

        let amountQuote = BigNumber(amount0 || 0);
        let conversionRate = BigNumber(1);
        let onChainAsset = selectedMenuItem == 'BUY' ? token1 : token0;
        let fiatAsset = selectedMenuItem == 'BUY' ? token0 : token1;

        onChainAsset.chainId = chainId;
        if (selectedMenuItem === 'BUY') {
            if (fiatAsset.symbol && fiatAsset.symbol !== 'USD') {
                conversionRate = BigNumber($CurrencyRates[fiatAsset.symbol]);
                amountQuote = amountQuote.div(conversionRate);
                quote = await getPriceQuote(amountQuote, stable, onChainAsset, chainId);
            } else {
                quote = await getPriceQuote(amountQuote, stable, onChainAsset, chainId);
            }
        } else {
            if (fiatAsset.symbol && fiatAsset.symbol !== 'USD') {
                conversionRate = BigNumber($CurrencyRates[fiatAsset.symbol]);
                quote = await getPriceQuote(amountQuote, onChainAsset, stable, chainId);
            } else {
                quote = await getPriceQuote(amountQuote, onChainAsset, stable, chainId);
            }
        }

        rate = selectedMenuItem === 'BUY' ? quote?.rightAmount || BigNumber(0) : quote?.rightAmount.times(conversionRate) || BigNumber(0);
        const rampRateDetails = await calculateRampPrices(
            onChainAsset, 
            rate, 
            selectedVenue, 
            fiatAsset, 
            selectedMenuItem == 'BUY' ? 'ONRAMP' : 'OFFRAMP', 
            true, 
            selectedMenuItem == 'BUY' ? undefined : amount0//amount0 for OFFRAMPS ONLY, leave undefined for onramps
        ).catch(
            (err) => {
                noLiquidity = true;
                priceLoader = false;
            }
        );
        if (!rampRateDetails) return (noLiquidity = true && (priceLoader = false));
        rampRate = rampRateDetails.finalRate;
        rampRate = { ...rampRate };
        otherRampRates = rampRateDetails.otherRates;
        otherRampRates = [...otherRampRates];

        if (!rampRate?.totalAmount || rampRate?.totalAmount.eq(0)) return (noLiquidity = true && (priceLoader = false));
        amount1 = rampRate?.totalAmount;

        try {
            refreshMinMax();
            outOfLiqBounds();
        } catch (err) {
            console.log('getMinMax call err: ', err);
        }

        //check if provider online hours is available
        try {
            providerHours = await fetchProviderHours(rampRate.maker) as string;
        } catch (e) {
            console.log('failed to fetch provider hours', e);
        }

        if (rampRate.reqCollateral == true) {
            preFetchCollateral(chainId)
                .then((data) => {
                    collatPreFetch = data;
                    collateral = $Collateral?.nativeForCollateral || BigNumber(0);
                })
                .catch((err) => {
                    console.log('collatPreFetch err: ', err);
                });
        } else {
            collateral = BigNumber(0);
        }
        // ###### START ######
        // DO NOT DELETE. this will be used for v3
        // collateralPriceData = await getPriceQuote(collateral, stable, RBXTokens[chainId], chainId, true);
        noLiquidity = false;
        priceLoader = false;

        // console.log('### - 7 -  collateralPriceData: ', collateralPriceData);
        // DO NOT DELETE. this will be used for v3
        // ###### END ######

        const [vidx, matches] = getIndexBreadthFirst(rampRate.venue, selectedVenue.shortName);
        matchedVenue.platform = matches[0]?.platform;
        matchedVenue.identHash = matches[0]?.identHash;

        getActionTitle();
        showPaymentMethods = true;
    };

    //everything from getNewTradeRate that comes after fetching the IProviderRates
    const onPickAlternativeRate = async (rate: IProviderRate) => {
        providerHours = rate.providerHours;
        //add initial matched rampRate to alternative rates
        otherRampRates.push(rampRate);
        //remove chosen rate from alternative rates
        otherRampRates = otherRampRates.filter((_rate) => _rate !== rate);
        otherRampRates = [...otherRampRates];
        //update rampRate with chosen rate
        rampRate = rate;

        if (!rampRate?.totalAmount || rampRate?.totalAmount.eq(0)) return (noLiquidity = true && (priceLoader = false));
        amount1 = rampRate?.totalAmount;

        try {
            refreshMinMax();
            outOfLiqBounds();
        } catch (err) {
            console.log('getMinMax call err: ', err);
        }

        if (rampRate.reqCollateral == true) {
            preFetchCollateral(chainId)
                .then((data) => {
                    collatPreFetch = data;
                    collateral = $Collateral?.nativeForCollateral || BigNumber(0);
                })
                .catch((err) => {
                    console.log('collatPreFetch err: ', err);
                });
        } else {
            collateral = BigNumber(0);
        }
        // ###### START ######
        // DO NOT DELETE. this will be used for v3
        // collateralPriceData = await getPriceQuote(collateral, stable, RBXTokens[chainId], chainId, true);
        noLiquidity = false;
        priceLoader = false;

        // console.log('### - 7 -  collateralPriceData: ', collateralPriceData);
        // DO NOT DELETE. this will be used for v3
        // ###### END ######

        const [vidx, matches] = getIndexBreadthFirst(rampRate.venue, selectedVenue?.shortName);
        matchedVenue.platform = matches[0]?.platform;
        matchedVenue.identHash = matches[0]?.identHash;

        getActionTitle();
        showPaymentMethods = true;
    };

    const getTrades = async () => {
        loadingItems = true;

        if (!$Wallet?.address || !$CryptKey?.length) {
            loadingItems = false;
            return;
        }
        // if ($P2PCache?.length > 0 && trades?.length == 0) trades = $P2PCache;

        const data = await getP2pTrades($Wallet?.address.toLowerCase()).catch((err) => {
            console.log('getP2pTrades err: ', err);
            return null;
        });

        //filter out duplicates
        if (data) {
            let nuTrades = data.filter(
                (trade, index, self) => index === self.findIndex((t) => t.asset.chainId == trade.asset.chainId && t.tradeId == trade.tradeId)
            );
            trades = [...nuTrades];
            // $P2PCache = [...trades];
        }

        loadingItems = false;
    };

    const getUserLiquidity = async () => {
        if (!$Wallet.address || !$CryptKey.length) return;

        const liqlistings = await getUserLiqList($Wallet.address.toLowerCase(), [$ChainId]);
        if (!liqlistings) return;
        userLiquidityListings = [...liqlistings];
        console.log('user liquidity', userLiquidityListings);
    };

    const getUserInfo = async () => {
        const uinfo = await getAddressInfo($Wallet.address);
        if (!uinfo) return;
        userInfo = { ...uinfo };
    };

    const clearSelections = () => {
        showMinMaxNotice = false;
        selectedVenue = null;
        token1 = null;
        amount1 = null;
        showMinMaxNotice = false;
        showTradeLockedWarning = false;
        getActionTitle();
    };

    const getActionTitle = async () => {
        tradeReady = isTradeReady();
        if (inPrompt) return (actionTitleTrade = SkipTexts[$Locale].submitting_trade);
        refreshMinMax();
        if (!$WalletConnected) return (actionTitleTrade = SkipTexts[$Locale].connect_wallet);
        if (!SkipShiftContracts[$ChainId]) return (actionTitleTrade = SkipTexts[$Locale].try_another_chain);
        if (!(await isWallet())) {
            actionTitleTrade = SkipTexts[$Locale].verify_wallet;
            return;
        } else if (!token0 || !token1) return (actionTitleTrade = SkipTexts[$Locale].select_assets);
        else if (!selectedVenue) return (actionTitleTrade = SkipTexts[$Locale].select_venue);
        else if (!venueId) return (actionTitleTrade = SkipTexts[$Locale].enter_venue_id);
        else if (rampRate.kypString && kypInfoString?.split('::').length != rampRate.kypString?.split('::').length)
            return (actionTitleTrade = SkipTexts[$Locale].enter_kyp_details);
        else if (!token0) return (actionTitleTrade = selectedMenuItem == 'BUY' ? SkipTexts[$Locale].select_fiat : SkipTexts[$Locale].select_token);
        else if (!token1) return (actionTitleTrade = selectedMenuItem == 'BUY' ? SkipTexts[$Locale].select_token : SkipTexts[$Locale].select_fiat);
        else {
            if (!rampRate.min.isZero() && !rampRate.max.isZero()) {
                let minAmt: BigNumber = BigNumber(0);
                let maxAmt: BigNumber = BigNumber(0);

                const exp =
                    selectedMenuItem == 'BUY' ? BigNumber(10).exponentiatedBy(token1?.decimals || 18) : BigNumber(10).exponentiatedBy(token0?.decimals || 18);

                minAmt = rampRate.min.div(exp);
                maxAmt = rampRate.max.div(exp);

                const tokenAmt = selectedMenuItem == 'BUY' ? BigNumber(amount1 || 0) : BigNumber(amount0 || 0);
                const tokenSymbol = selectedMenuItem == 'BUY' ? token1.symbol : token0.symbol;

                if (tokenAmt && tokenAmt?.lt(minAmt)) return (actionTitleTrade = `Min ${minAmt.toFixed(2)} ${tokenSymbol}`);

                if (tokenAmt && tokenAmt?.gt(maxAmt)) return (actionTitleTrade = `Max ${maxAmt.toFixed(2)} ${tokenSymbol}`);
            }

            if (amount1) (tradeReady = true) && (actionTitleTrade = SkipTexts[$Locale].submit_trade);
            else actionTitleTrade = `Enter ${token0.symbol} amount`;
        }
    };

    const isTradeReady = () => {
        return (
            (token0 &&
                token1 &&
                !isObjectEmpty(token0) &&
                !isObjectEmpty(token1) &&
                selectedVenue &&
                !!venueId &&
                amount0 &&
                amount1 &&
                amount0.gt(0) &&
                amount1.gt(0) &&
                rampRate.totalAmount.gt(0)) ||
            false
        );
    };

    const onCreateOrder = async (rate: IProviderRate) => {
        if (!isTradeReady) return;

        if (kypInputFields.length > 0 && kypInfoString?.split('::').length != rampRate.kypString.split('::').length) {
            addActionLogLine('Please fill out all KYP fields');
            return;
        }

        inPrompt = true;
        getActionTitle();

        let destination: string;

        $ShowActionLogs = true;

        matchedVenue.takerId = venueId;

        addActionLogLine('Initiating Ramp Trade...');

        try {
            const minAmt = new BigNumber(rate.min);
            const cryptoAsset = selectedMenuItem == 'BUY' ? token1 : token0;
            const cryptoAmount = selectedMenuItem == 'BUY' ? BigNumber(amount1!) : BigNumber(amount0!); // these get checked in isTradeReady()
            const fiatAsset = selectedMenuItem == 'BUY' ? token0 : token1;

            if (!cryptoAsset || !cryptoAsset?.chainId) {
                addActionLogLine('Please select a valid asset.');
                inPrompt = false;
                getActionTitle();
                return;
            }
            if (cryptoAsset?.chainId !== $ChainId) {
                const switchedChain = await chainSwitcher(cryptoAsset?.chainId);
                if (switchedChain !== 1) {
                    addActionLogLine('Unsupported chain. Please switch to a supported chain.');
                    $ShowActionLogs = false;
                    inPrompt = false;
                    getActionTitle();
                    return;
                }
            }

            if (cryptoAmount && cryptoAmount?.lt(minAmt.div(10 ** (cryptoAsset?.decimals || 18)))) {
                actionTitleTrade = 'Minimum Not Met';
                $ShowActionLogs = false;
                inPrompt = false;
                getActionTitle();
                return;
            }
            if (fiatAsset && rate && rate?.totalAmount.eq(0)) {
                actionTitleTrade = 'Not Enough Liquidity';
                $ShowActionLogs = false;
                inPrompt = false;
                getActionTitle();
                return;
            }

            destination = selectedMenuItem === 'BUY' ? $Wallet.address : rate.maker;

            if (!cryptoAsset || !cryptoAsset.chainId) {
                inPrompt = false;
                getActionTitle();
                return;
            }

            order = {
                listingId: rampRate.listingId,
                amount: cryptoAmount.toString(),
                asset: cryptoAsset,
                destination: destination,
                venue: matchedVenue,
                type: selectedMenuItem == 'BUY' ? 'ONRAMP' : 'OFFRAMP',
                maker: rate.maker,
                reqCollateral: rate.reqCollateral,
                kypInfo: kypInfoString
            };

            let autoCollateralize: boolean = false;
            if(rampRate.reqCollateral){
                // check if wallet has enough collateral
                // if($Collateral) console.log('collateral test: store exists', $Collateral)
                if(+$Collateral.balance == 0) await updateCollateral();//check if they have RBX for collat again right before submitting
                const requiredCollateral = await collateralNeeded($ChainId, true);
                const heldCollateral = $Collateral.balance;
                autoCollateralize = (requiredCollateral?.isGreaterThan(heldCollateral) && rampRate.reqCollateral) || false;

                // console.log('required collateral test', requiredCollateral.toString());
                // console.log('held collateral test', heldCollateral);
                // console.log('autoCollateralize collateral test', autoCollateralize);

                if (autoCollateralize) addActionLogLine(`Auto collateralizing trade with ${$Chains[$ChainId]?.nativeCurrency?.symbol}...`);
                else if (requiredCollateral.gt(0)) addActionLogLine('Using RBX balance for collateral...');
            }

            const result = await createP2pTrade(order as IP2PTradeLiq, autoCollateralize);

            if (result == true) {
                await updateUserData().then(() => {
                    selectedMenuItem = 'LIST';
                });
            } else {
                console.error('Trade creation failed :( ');
                showTradeLockedWarning = false;
            }
            $ShowActionLogs = false;
            inPrompt = false;
            getActionTitle();
        } catch (e) {
            showTradeLockedWarning = false;
            $ShowActionLogs = false;
            inPrompt = false;
            getActionTitle();
        }
    };

    const onShowSettingsClick = () => {
        showTransactionSettings = !showTransactionSettings;
    };

    const onShowPaymentMethodsClick = () => {
        step1assignData();
        showPaymentMethods = !showPaymentMethods;

        if (!showPaymentMethods) {
            getNewTradeRate();
        }

        if (selectedMenuItem) {
            getActionTitle();
        }
    };

    const onShowMoreRatesClick = () => {
        showMoreRates = !showMoreRates;
    };

    const onShowToken0ListClick = () => {
        clearSelections();
        step1assignData();

        showToken0List = !showToken0List;
        selectedVenue = null;
        getActionTitle();
        if (amount0) getNewTradeRate();
    };
    const onShowToken1ListClick = () => {
        amount1 = null;
        step1assignData();

        selectedVenue = null;
        showToken1List = !showToken1List;
        getActionTitle();
        if (amount0){ 
            getNewTradeRate();
        }else if(token0 && token1){
            let currency, asset, direction: string;
            currency = selectedMenuItem == "BUY" ? token0?.symbol || "" : token1?.symbol || "";
            asset = selectedMenuItem == "BUY" ? token1?.symbol || "" : token0?.symbol || "";    
            direction = selectedMenuItem == "BUY" ? "1" : "0";
            spreadRange = getSpreadRange(currency, asset, direction) || "0%";
        }
    };

    const onOpenChatClick = (event: any) => {
        order = event.detail.order;
        chatTarget = event.detail.target;
    };

    const onPayIDInput = (val: string) => {
        venueId = val;
        getActionTitle();
    };

    const onSwitchDirectionClick = async () => {
        clearSelections();
        step1assignData();

        token0 = null;
        token1 = null;
        amount0 = null;
        amount1 = null;
        selectedVenue = null;
        loadingItems = false;

        getActionTitle();
    };

    const onChainSwitch = async () => {
        console.log('switching chains to', $ChainId);
        getActionTitle();
        showTransactionSettings = false;
        showPaymentMethods = false;
        selectedVenue = null;
        loadingUserListings = true;
        await getUserLiquidity();
        loadingUserListings = false;

        if ($Wallet?.address && $WalletConnected && SkipShiftContracts[$ChainId]) updateUserData();
        step1assignData();

        showPaymentMethods = true;
    };

    const onClearClick = () => {
        token0 = null;
        token1 = null;
        amount0 = null;
        amount1 = null;
        selectedVenue = null;
        providerHours = undefined;
        showMinMaxNotice = false;
        showTransactionSettings = false;
        showTradeLockedWarning = false;
        $TxError = '';
        showErrors = false;
        rampRate = {
            spread: 0,
            totalAmount: new BigNumber(0),
            maker: '',
            venue: [],
            listingId: 0,
            min: BigNumber(0),
            max: BigNumber(0),
            kypString: ''
        };
        kypInputFields = [];
        venueId = '';
        otherRampRates = [];
        getActionTitle();
    };

    const onConnectWallet = async () => {
        await connect();
        getActionTitle();

        if (!SkipShiftContracts[$ChainId] && $ChainId != 0) {
            $ShowActionLogs = true;
            addActionLogLine('Unsupported chain. Please switch to ETH, BSC, or AVAX.');
            $ShowActionLogs = false;

            getActionTitle();
            // return;
        }
        if (!(await isWallet())) {
            if (selectedMenuItem == 'LIST' && !$USER_SETTINGS.providerMode) selectedMenuItem = 'BUY';
            // $P2PCache = [];
            const init = await initXmtp();
            if (!init) return;
            updateUserData();
            if (userInfo && +userInfo.tradeLocked > 0) showTradeLockedWarning = true;
            getUserLiquidity();
            return;
        }
    };

    const onActionClick = async () => {
        if (!$WalletConnected) {
            console.log('wallet not connected');
            if(isMobile()) await onConnectWallet();
            else toggleWallets();

            getActionTitle();
            // return;
        }
        if (!SkipShiftContracts[$ChainId] && $ChainId != 0) {
            $ShowActionLogs = true;
            addActionLogLine('Unsupported chain. Please switch to ETH, BSC, or AVAX.');
            $ShowActionLogs = false;

            getActionTitle();
            // return;
        }
        if (!(await isWallet())) {
            if (selectedMenuItem == 'LIST' && !$USER_SETTINGS.providerMode) selectedMenuItem = 'BUY';
            // $P2PCache = [];
            const init = await initXmtp();
            if (!init) return;
            updateUserData();
            if (userInfo && +userInfo.tradeLocked > 0) showTradeLockedWarning = true;
            getUserLiquidity();
            return;
        }

        if (tradeReady) await onCreateOrder(rampRate);
        getActionTitle();
    };

    function refreshMinMax() {
        if (!token0 || !token1) return;
        if (!token0?.symbol || !token1?.symbol) return;
        // token1 = { ...token1 };
        // token0 = { ...token0 };
        console.log('inside refreshMinMax:');
        const exp = selectedMenuItem == 'BUY' ? BigNumber(10).exponentiatedBy(token1?.decimals || 18) : BigNumber(10).exponentiatedBy(token0?.decimals || 18);
        chainId = (selectedMenuItem === 'BUY'
                        ? token1?.chainId
                        : token0?.chainId) ?? $ChainId;
        console.log('refreshMinMax getMinMax: ', min.toFixed(), max.toFixed());
        ({ min, max } = getMinMax(token0?.address || token0?.symbol, token1?.address || token1?.symbol, chainId, selectedMenuItem));
        // rampRate.min = min;
        // rampRate.max = max;
        console.log('refreshMinMax getMinMax: ', min.toFixed(), max.toFixed());

        minMaxSymbol = (selectedMenuItem == 'BUY' ? token1?.symbol : token0?.symbol) || '🪙';
        min = min.div(exp); //.toFixed(2);
        max = max.div(exp); //.toFixed(2);
    }

    function outOfLiqBounds() {
        console.log('outOfLiqBounds inside');
        if (!amount1) return;

        const amt = selectedMenuItem == 'BUY' ? amount1 : amount0;
        const token = selectedMenuItem == 'BUY' ? token1 : token0;

        let res = amt && (amt.lt(rampRate.min.div(10 ** (token?.decimals || 18)) || 0) || amt.gt(rampRate.max.div(10 ** (token?.decimals || 18)) || 0));
        console.log(
            'outOfLiqBounds res 0: ',
            res,
            rampRate.min.div(10 ** (token?.decimals || 18))?.toString(),
            rampRate.max.div(10 ** (token?.decimals || 18))?.toString(),
            amt?.toString()
        );

        // if(!res){
        //     res = (token0 && token1 && (!amount0 || amount0?.isNaN() || amount0?.isZero()));
        // }
        console.log('outOfLiqBounds res 1: ', res);
        showMinMaxNotice = res || false;
    }

    const tradeSizeMessage = () => {
        let symbol = selectedMenuItem == 'BUY' ? token1?.symbol : token0?.symbol;
        const token = selectedMenuItem == 'BUY' ? token1 : token0;

        const validListing = $AllLiquidityStore.returnData.find((rate) => {
            if(
                (rate.asset.address == token1?.address || rate.asset.address == token0?.address) && 
                (rate.currency == token0?.symbol || rate.currency == token1?.symbol) &&
                (selectedMenuItem == 'BUY' ? rate.type == '1' : rate.type == '0')
            ){
                // console.log('tradesizemessage: find listing rate', rate);
                if(amount1 && selectedMenuItem == "BUY" && rate.asset.decimals){
                    return BigNumber(rate.min).div(10**rate.asset.decimals).lte(amount1) && BigNumber(rate.max).div(10**rate.asset.decimals).gte(amount1)
                }else if(amount0 && selectedMenuItem == "SELL" && rate.asset.decimals){
                    return BigNumber(rate.min).div(10**rate.asset.decimals).lte(amount0) && BigNumber(rate.max).div(10**rate.asset.decimals).gte(amount0)
                }else{
                    return false;
                }
            }else{
                return false;
            }
        });

        // console.log('tradesizemessage: valid listing:', validListing?.venues);

        validListing?.venues.forEach(venue => {
            //@ts-ignore
            venue.platform = venue[0]
        })

        let validListingFoundMsg: string = '';
        if(validListing?.venues){
            validListingFoundMsg = `${SkipTexts[$Locale].trades_between} ` + 
                `<span style="color: var(--active-menu-text-color)">${BigNumber(validListing.min).div(10**18).toFixed(2)}</span>` + 
                ` ${SkipTexts[$Locale].and} ` + 
                `<span style="color: var(--active-menu-text-color)">${BigNumber(validListing.max).div(10**18).toFixed(2)} ${token?.symbol}</span>` + 
                ` ${SkipTexts[$Locale].minmax_notif_2} ` + 
                `<span style="color: var(--active-menu-text-color)">${validListing.venues[0].platform}</span>.`;
        }


        return `${SkipTexts[$Locale].minmax_notif_1} <span 
                style="color: var(--active-menu-text-color)">${rampRate.min.div(10 ** (token?.decimals || 18)).toFixed(2)}</span> ${SkipTexts[$Locale].and} <span 
                style="color: var(--active-menu-text-color)">${rampRate.max.div(10 ** (token?.decimals || 18)).toFixed(2)} ${symbol || '🪙'}</span> ${SkipTexts[$Locale].on} ${selectedVenue?.shortName}. ` + validListingFoundMsg;
    };

    const updatePayIdSegmented = (val: string, i: number, total: number) => {
        let parts = [];
        if (venueId) {
            parts = venueId.split('::');
        } else {
            while (parts.length < total) {
                parts.push('');
            }
        }

        parts[i] = val;
        venueId = parts.join('::');
        console.log('updated user pay id', venueId);

        getActionTitle();
    };

    const toggleWallets = async () => {
        console.log('toggling wallet... ', showWallets);
        showWallets = !showWallets;
    };
</script>

<div class="flex flex-col skiproot md:min-w-[25em] max-w-[100%]" use:autoAnimate class:shadow-off={inWidget}>
    <!--BUY/SELL/SETTINGS-->
    <div class="buysell flex items-center justify-between pt-4 px-1 md:px-6 ml-6 md:ml-4">
        <div>
            <div class="flex flex-nowrap">
                {#if $USER_SETTINGS.providerMode}
                    <button
                        on:click={() => {
                            selectedMenuItem = 'LIQ';
                            // getUserLiquidity();
                        }}>
                        <div class="flex">
                            <span
                                class={selectedMenuItem === 'LIQ' ? `font-bold menu-item active` : `menu-item inactive`}
                                >{SkipTexts[$Locale].my_listings}
                            </span>
                        </div>
                    </button>
                    <button
                        on:click={() => {
                            selectedMenuItem = 'ADD';
                        }}>
                        <div class="flex">
                            <span
                                class={selectedMenuItem === 'ADD' ? `pl-2 font-bold menu-item active` : `pl-2 menu-item inactive`}
                                >{SkipTexts[$Locale].add_liquidity}
                            </span>
                        </div>
                    </button>
                {:else}
                    <button
                        on:click={() => {
                            selectedMenuItem = 'BUY';
                            onSwitchDirectionClick();
                        }}>
                        <div class="flex">
                            <span
                                class={selectedMenuItem === 'BUY' ? `font-bold menu-item active` : `menu-item inactive`}
                                >{SkipTexts[$Locale].buy_crypto}
                            </span>
                        </div>
                    </button>
                    <button
                        on:click={() => {
                            selectedMenuItem = 'SELL';
                            onSwitchDirectionClick();
                        }}>
                        <span
                            class={selectedMenuItem === 'SELL' ? `pl-2 font-bold menu-item active` : `pl-2 menu-item inactive`}>{SkipTexts[$Locale].sell_crypto}</span>
                    </button>
                {/if}

                {#if $Wallet?.address && ($WalletVerified || $CryptKey?.length)}
                    <button
                        on:click={() => {
                            selectedMenuItem = 'LIST';
                        }}>
                        <span
                            id="myTrades"
                            class={selectedMenuItem === 'LIST' ? `pl-2 font-bold menu-item active` : `pl-2 menu-item inactive`}>{SkipTexts[$Locale].my_trades}</span>
                    </button>
                {/if}
            </div>
            {#if showTransactionSettings}
                <div class="w-fit h-fit">
                    <TransactionSettings
                        bind:switchValueInactiveTrades={$USER_SETTINGS.showInactive}
                        on:chainSwitch={onChainSwitch}
                        on:close={(_) => (showTransactionSettings = !showTransactionSettings)} />
                </div>
            {/if}
            <!--keep list components separate so we can easily pass in different token lists from root component-->
            {#if showToken0List}
                <TokenList
                    bind:selectedToken={token0}
                    tokenList={itemList0}
                    fiat={selectedMenuItem == 'BUY'}
                    switcherToggle={selectedMenuItem == 'BUY' ? false : true}
                    {selectedMenuItem}
                    on:close={onShowToken0ListClick} />
            {/if}
            {#if showToken1List}
                <TokenList
                    selectedCurrency={token0?.symbol || ''}
                    bind:selectedToken={token1}
                    tokenList={itemList1}
                    switcherToggle={selectedMenuItem == 'BUY' ? true : false}
                    {selectedMenuItem}
                    fiat={selectedMenuItem == 'SELL'}
                    on:close={onShowToken1ListClick} />
            {/if}
            {#if showMoreRates}
                <AdvancedLiquidityList
                    selectedAsset={selectedMenuItem == "BUY" ? token1 : token0}
                    rates={otherRampRates}
                    on:rateSelected={(e) => onPickAlternativeRate(e.detail.rate)}
                    on:close={onShowMoreRatesClick} />
            {/if}
        </div>

        <div class="pl-0 md:pl-2 mr-2">
            <!-- hiding this for now until we can implement -->
            <div class="text-sm text-pink-400 helpMode_container hidden">
                <label class="checkbox style-d">
                    <input type="checkbox" />
                    <div class="checkbox__checkmark" />
                    <div class="checkbox__body">HELP MODE</div>
                </label>
            </div>
            <!-- hiding this for now until we can implement -->
            <button on:click={onShowSettingsClick}>
                <div class="flex">
                    <span style="fill: var(--active-menu-text-color); stroke: var(--active-menu-text-color);">
                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path
                                style="fill: var(--active-menu-text-color); stroke: var(--active-menu-text-color);"
                                d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
                        </svg>
                    </span>
                </div>
            </button>
        </div>
    </div>

    {#if selectedMenuItem == 'BUY' || selectedMenuItem == 'SELL'}
        <!--TOP INPUT-->
        <div class="topinput px-6 pt-4" id="_top_input">
            <TokenInput
                pulse={!token0}
                on:getNewRate={getNewTradeRateDebounced}
                on:clear={onClearClick}
                disabled={false}
                bind:amount={amount0}
                bind:selectedToken={token0}
                on:showTokenList={onShowToken0ListClick}
                actionText={selectedMenuItem == 'BUY' ? SkipTexts[$Locale].select_fiat : SkipTexts[$Locale].select_token}
                message={SkipTexts[$Locale].you_pay} />
        </div>
        <!--BOTTOM INPUT-->
        <div class="bottominput px-6 pt-4" id="_bottom_input">
            <TokenInput
                pulse={!token1}
                loading={priceLoader}
                bind:amount={amount1}
                subtractPlatformFee={selectedMenuItem == 'BUY'}
                disabled={!token0}
                disableInput={true}
                on:clear={onClearClick}
                bind:selectedToken={token1}
                on:showTokenList={onShowToken1ListClick}
                actionText={selectedMenuItem !== 'BUY' ? SkipTexts[$Locale].select_fiat : SkipTexts[$Locale].select_token}
                message={SkipTexts[$Locale].you_receive}
                notice={showMinMaxNotice} />
        </div>
        {#if otherRampRates.length > 0}
            <button on:click={onShowMoreRatesClick} class="flex flex-row-reverse mr-6">
                <span class="text-sm " style="color: var(--active-menu-text-color)">{SkipTexts[$Locale].view_more_rates}</span>
            </button>
        {/if}
        {#if showMinMaxNotice}
            <div class="pl-16 info flex flex-col">
                <div class="flex pt-2">
                    <PaymentMethodButton
                        bind:availableVenues={venuesList}
                        on:showPaymentMethods={onShowPaymentMethodsClick}
                        pulse={$WalletConnected && !selectedVenue}
                        loading={loadingItems}
                        message={selectedVenue?.shortName}
                        venue={selectedVenue} />
                    {#if showPaymentMethods}
                        <PaymentMethods bind:availableVenues={venuesList} bind:selectedVenue on:close={onShowPaymentMethodsClick} />
                    {/if}
                </div>
            </div>
            <InfoBox 
                title={'❗️ Notice'} 
                content={tradeSizeMessage()}>
            </InfoBox>
        {/if}

        {#if !token0 || (!token1 && !showErrors)}
            <InfoBox
                title="💡 {SkipTexts[$Locale].information}"
                content={SkipTexts[$Locale].select_both_message} />
            <!--TODO: get translations for all this shit-->
            <InfoBox
                title="💡 {SkipTexts[$Locale].information}"
                content={selectedMenuItem == 'BUY' ? SkipTexts[$Locale].post_submit_message_on : SkipTexts[$Locale].post_submit_message_off} />
        {:else if !showMinMaxNotice}
            <!--TRADE DETAILS/SETTINGS SECTION-->
            <div class="flex pt-2">
                <div class="w-[1px] ml-12 h-full bg-gray-300" />
                <div class="flex flex-col">
                    <div class="pl-2 info flex flex-col">
                        <div class="flex pt-2">
                            <PaymentMethodButton
                                bind:availableVenues={venuesList}
                                on:showPaymentMethods={onShowPaymentMethodsClick}
                                pulse={$WalletConnected && !selectedVenue}
                                loading={loadingItems}
                                message={selectedVenue?.shortName}
                                venue={selectedVenue} />
                            {#if showPaymentMethods}
                                <PaymentMethods bind:availableVenues={venuesList} bind:selectedVenue on:close={onShowPaymentMethodsClick} />
                            {/if}
                        </div>
                    </div>
                    <hr class="mt-2 ml-[1em] border-gray-300" />
                    <!--SECTION 1-->
                    <div class="info flex flex-col pt-2">
                        <div class="flex info">
                            {#if rampRate?.totalAmount?.gt(0)}
                                {#if selectedMenuItem == 'BUY'}
                                    <span class="pl-2 infoText">
                                        1 {token0?.symbol || '💵'} =
                                        {rampRate?.totalAmount?.gt(0) && BigNumber(amount0 || 0)?.gt(0)
                                            ? subZero(rampRate?.totalAmount.div(amount0 || 0).toFixed(6))
                                            : '???'}
                                        {token1?.symbol || '🪙'}
                                    </span>
                                {:else}
                                    <span class="pl-2 infoText">
                                        1 {token0?.symbol || '💵'} =
                                        {rampRate?.totalAmount?.gt(0) && BigNumber(amount0 || 0)?.gt(0)
                                            ? subZero(rampRate?.totalAmount.div(amount0 || 0).toFixed(6))
                                            : '???'}
                                        {token1?.symbol || '🪙'}
                                    </span>
                                {/if}
                                <!-- <span class="pl-2" style="color: #{infoText}">Rate</span> -->
                            {/if}
                            {#if rampRate?.reqCollateral && $Collateral?.nativeForCollateral.gt(0)}
                                <span class="info pl-2 infoText">{SkipTexts[$Locale].requires_collateral}</span>
                            {:else}
                                <span class="info pl-2 infoText">{SkipTexts[$Locale].no_collat_required_id_inputs}</span>
                            {/if}
                        </div>

                        <!--SECTION 2-->
                        <!--TODO: move this section to a separate component that takes {inputPlaceholder, inputType}[]-->
                        {#if selectedVenue}
                            <IdInputs
                                selectedVenue={selectedVenue}
                                paymentMethodsMap={paymentMethodsMap}
                                rampRate={rampRate}
                                selectedMenuItem={selectedMenuItem}
                                venueId={venueId}
                                on:idInput={(e) => onPayIDInput(e.detail.val)}
                                on:idInputSegmented={(e) => updatePayIdSegmented(e.detail.val, e.detail.i, e.detail.total)}
                                on:kypInput={(e) => onKypInputChange(e.detail.val, e.detail.i)}
                            />

                        {/if}

                        <div class="flex flex-col pt-2 min-width-[12em] max-w-[20em]" id="_trade_rate_details">
                            <div class="flex">
                                <TradeDetails
                                    {rampRate}
                                    {spreadRange}
                                    {min}
                                    {max}
                                    {minMaxSymbol}
                                    asset={selectedMenuItem == 'BUY' ? token1 : token0}
                                    {providerHours} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {#if showErrors}
                <InfoBox
                    title={'⛔️ Error'}
                    content={formatError($TxError, selectedMenuItem == 'BUY' ? token1 || {} : token0 || {}, rampRate)}
                    on:close={() => (showErrors = false)} />
            {:else}
                {#if !showTradeLockedWarning && rampRate?.reqCollateral && $Collateral?.nativeForCollateral.gt(0)}
                    <InfoBox
                        content={`${SkipTexts[$Locale].autocollat_warning} ~${$Collateral?.nativeForCollateral.toFixed(5)} ${
                            $Chains[chainId || $ChainId].nativeCurrency?.symbol
                        }${SkipTexts[$Locale].autocollat_warning_2}`} />
                {:else if !showTradeLockedWarning && !rampRate?.reqCollateral && rampRate?.venue.length > 0}
                    <InfoBox 
                        content={SkipTexts[$Locale].no_collat_required
                        } 
                    />
                {/if}
                {#if !showErrors && !rampRate?.reqCollateral && (token0.address?.toLowerCase() == Contracts.jimmyDai.toLowerCase() || token1?.address?.toLowerCase() == Contracts.jimmyDai.toLowerCase())}
                    <InfoBox
                        content={`The DAI available for purchase here is not the officially supported DAI and does not maintain a 1:1 peg with USD value, akin to traditional stablecoins. Users are advised to exercise caution and conduct thorough research before engaging in transactions involving this token.`} />
                {/if}
                {#if showTradeLockedWarning}
                    <InfoBox
                        title={'⚠️ Warning ⚠️'}
                        content={`${SkipTexts[$Locale].pending_trade_warning} ${$Chains[$ChainId]?.shortName || $ChainId}${SkipTexts[$Locale].pending_trade_warning_2}`} />
                {/if}
            {/if}
        {/if}

        <!--ACTION BAR-->
        <div class="actionbar">
            <button
                disabled={inPrompt}
                class="buttonGradient"
                on:click={onActionClick}
                class:action-bar-alert={showErrors || (!$WalletVerified && $WalletConnected)}
              >

                <div class={$WalletConnected ? ' text-white' : ' text-white animate-bounce'}  >{actionTitleTrade}</div>
            </button>
        </div>

        <!--POWERED BY-->
        {#if inWidget}
            <div class="flex flex-row-reverse pr-4 pb-0 mb-2">
                <a href="https://skipshift.io" target="_blank" rel="noopener noreferrer">
                    <span class="text-[0.675rem]" style="color: var(--active-menu-text-color)"><i>powered by</i> SkipShift</span>
                </a>
            </div>
        {/if}
    {:else if selectedMenuItem == 'LIST'}
        <TradeList
            {arbitrationEnabled}
            on:reload={updateUserData}
            on:openChat={onOpenChatClick}
            {userInfo}
            loading={loadingItems}
            tradeList={trades} />
    {:else if selectedMenuItem == 'LIQ'}
        <LiqList
            on:chainSwitch={onChainSwitch}
            liqList={userLiquidityListings || []}
            loading={loadingUserListings}
            switchValueInactive={$USER_SETTINGS.showInactive}>
        </LiqList>
        {#if !$WalletVerified || !$WalletConnected}
            <div class="actionbar">
                <button class="buttonGradient" on:click={onActionClick}>
                    <div class={$WalletConnected ? ' text-white' : ' text-white animate-bounce'}>{actionTitleTrade}</div>
                </button>
            </div>
        {/if}
    {:else if selectedMenuItem == 'ADD'}
        {#if !$WalletVerified && !$WalletConnected}
        <div class="pending-holder">
            <i class="fad fa-exchange-alt text-9xl text-sky-200"></i>
            <span class="pt-4 mx-8 text-center text-gray-400" >{!$WalletConnected ? 'Connect Wallet' : SkipTexts[$Locale].no_listings_found}</span>
        </div>
            <div class="actionbar">
                <button class="buttonGradient" on:click={onActionClick}>
                    <div class={$WalletConnected ? ' text-white' : ' text-white animate-bounce'}>{actionTitleTrade}</div>
                </button>
            </div>
        {:else}
            <AddLiquidity {userInfo}/>
        {/if}
    {/if}
</div>

{#if showWallets}
    <WalletProviders on:close={toggleWallets} on:verify={onActionClick}/>
{/if}

<style lang="scss">

.pending-holder {
        display: flex;
        flex-direction: column;
        height: 90%;
        align-items: center;
        justify-content: center;
    }
    .shadow-off {
        box-shadow: none !important;
    }
    .skiproot {
        @apply md:min-w-[27.458rem] md:max-w-[27.458rem] w-[100%];
        @apply max-h-[39.688rem] min-h-[39.688rem];
        font-family: 'Urbanist', sans-serif;
        border-radius: 16px;
        z-index: 3;
        background: var(--background-color);
        box-shadow:
            0px 10px 2px 0px rgba(0, 0, 0, 0.1),
            0px 8px 4px 0px rgba(0, 0, 0, 0.04),
            0px 24px 16px 0px rgba(0, 0, 0, 0.04),
            0px 32px 24px 0px rgba(0, 0, 0, 0.01),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        // height: 100%;
    }

    @media (max-width: 650px) {
        .skiproot {
            min-width: 95vw;
        }
    }
    .buysell {
        color: rgba(0, 0, 0, 0.4);
        /* font-family: Inter; */
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 120% */
    }

    @media (min-width: 768px) {
        .buysell {
            font-size: 1.25rem;
        }
    }

    .description-text {
        color: #ababab;
        font-weight: 200;
    }
    .info {
        font-size: 0.75rem;
    }
    .id-input::placeholder {
        color: var(--placeholder-color);
        opacity: 1;
    }
    .actionbar button {
        width: 90%;
        margin: auto;
        border-radius: 8px;
        padding: 0.75rem 0rem;
        margin-bottom: 0;
    }
    .actionbar {
        display: flex;
        flex-grow: 1;
        padding-bottom: 1.5em;
        padding-top: 0em;
        top: 0px;
        position: relative;
        width: 100%;
    }

    .action-bar-alert {
        background: linear-gradient(90deg, rgb(233, 14, 14) 0%, rgba(233, 14, 14, 0.5) 100%) !important;
    }

    // * {
    //     /* font-size: 1rem; */
    //     font-family: 'Urbanist', sans-serif;
    // }

    .helpMode_container {
        border: 1px solid fuchsia;
        border-radius: 25px;
        // padding: 1px 5px;
        position: absolute;
        top: 0px;
        right: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 19px;
        border-right: 0px;
        border-top: 0px;
        border-color: lightgray;
        padding: 0px 10px;
        font-variant: all-small-caps;
        font-weight: 900;
    }

    .checkboxes {
        max-width: 700px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }
    .checkboxes__row {
        display: flex;
    }
    .checkboxes__row:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
    .checkboxes__item {
        padding: 15px;
        width: 50%;
    }

    .checkbox.style-d {
        display: inline-block;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkbox.style-d input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkbox.style-d input:checked ~ .checkbox__checkmark {
        @apply bg-pink-400;
        // background-color: #f7cb15;
    }
    .checkbox.style-d input:checked ~ .checkbox__checkmark:after {
        opacity: 1;
    }
    .checkbox.style-d:hover input ~ .checkbox__checkmark {
        background-color: #eee;
    }
    .checkbox.style-d:hover input:checked ~ .checkbox__checkmark {
        @apply bg-pink-200;
        // background-color: #f7cb15;
    }
    .checkbox.style-d:hover input ~ .checkbox__body {
        @apply text-pink-400;
        // color: #f7cb15;
    }
    .checkbox.style-d .checkbox__checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
        transition: background-color 0.25s ease;
        border-radius: 11px;
    }
    .checkbox.style-d .checkbox__checkmark:after {
        content: '';
        position: absolute;
        left: 8px;
        top: -5px;
        width: 7px;
        height: 16px;
        border: solid #333;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.25s ease;
    }
    .checkbox.style-d .checkbox__body {
        color: #333;
        line-height: 1.4;
        transition: color 0.25s ease;
    }
</style>
