<script lang="ts">
  import SwitchComponent from "../Common/SwitchComponent.svelte";
  import { USER_SETTINGS } from "../../Data/TransactionSettings";
  import { ChainId } from "../../Data/Wallet";
  import { Chains } from "../../Data/Chains";
  import { WalletConnected, Wallet } from "../../Data/Wallet";
  import { web } from "../../Data/Web3Store";
  import { rpcLooper } from "../../API/helpers/rpcLooper";
  import { createEventDispatcher, onMount } from "svelte";
  import chainSwitcher from "../../API/helpers/chainSwitcher";
  import { SkipTexts, Locale, isSupportedLanguage } from "../../Data/Locale";
  import { addActionLogLine } from "../../Data/ActionLogs";
  import { goodRPCs } from "../../Data/Chains";
  import updateRPC from "../../API/helpers/rpcFix";
  import chains from "../../Data/Chains";
  import { hexToRgba } from "../../API/helpers/hexToRGBA";
  import type { IToken } from "../../Data/Tokens";
  import BigNumber from "bignumber.js";
  import { getAddressInfo, getCollateralTokenInfo, decollateralize } from "../../API/P2P";
  import { IUserInfo } from "../../Data/P2P";
  import LoadBlaster from '../Common/LoadBlaster.svelte';

  const dispatch = createEventDispatcher();

  export let switchValueInactiveTrades: boolean = false;
  // export let buttonGradient: string;

  let userInfo: IUserInfo | undefined;
  let collateralToken: IToken | undefined;
  let loading = false;

  // export let bgColor: string;
  // export let textColor: string;

  const onChainSelectClick = async (chainId: number) => {
    if(!$WalletConnected){
      $ChainId = chainId;
      const tempWeb = await rpcLooper(chainId);

      if(!tempWeb) return;
      $web = tempWeb;
    }

    await chainSwitcher(chainId);
    dispatch('chainSwitch');
  }

  const onLocaleClick = (locale: string) => {
      if (!!locale && isSupportedLanguage(locale)) $Locale = locale;
      else $Locale = 'en';
  };

  const getUserCollateral = async () => {
    loading = true;
    userInfo = await getAddressInfo($Wallet.address);
    collateralToken = await getCollateralTokenInfo();
    console.log('getUserCollateral token', collateralToken);
    console.log('getUserCollateral collat amt', userInfo);
    loading = false;
  };

  const onDecollateralizeClick = async () => {
    if(!$WalletConnected){
        console.error('no wallet connection :(');
        return;
    }

    if(!userInfo) return;

    if(+userInfo.tradeLocked != 0){
      addActionLogLine('You cannot decollateralize while you have pending trades. Please cancel or complete any pending trades and try again.');
      return;
    }

    addActionLogLine(`Withdrawing collateral...`)

    const tx = await decollateralize();
    if(!tx){
      console.error('decollat error');
    }else{
        // addActionLogLine(ActionLogTexts[$Locale].transaction_sent);
        userInfo = await getAddressInfo($Wallet.address);
        if(!userInfo) return;
        userInfo = {...userInfo};
    }
  }


  const fixRpc = async () => {
    if(!$WalletConnected){
        addActionLogLine('Please connect wallet to fix RPC.');
        return;
    }
    addActionLogLine(`🚀 Adding a more reliable RPC URL for ${chains[$ChainId].name}... Enjoy smoother transactions! 🌐✨`)
    await updateRPC($ChainId, goodRPCs[$ChainId]);
  };

  onMount(async() => {
    const params = new URLSearchParams(window.location.search);
    if($WalletConnected) await getUserCollateral();
    console.log('params', params);
  })
</script>

  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div on:click={() => dispatch('close')} class="w-screen h-screen z-0 bg-transparent fixed top-0 left-0">
  </div>

<main class="flex flex-col settings-container text-sm infoText transaction-settings">
  <span class="infoText">{SkipTexts[$Locale].settings}</span>
  <span class="infoText pt-4 italic">{SkipTexts[$Locale].selected_network}</span>
  <div class="grid grid-cols-4 gap-4 mt-2">
    <button on:click="{() => onChainSelectClick(1)}">
      <img
        class={$ChainId === 1 ? "buttonGradient text-white chain-button buttonGradient" : "chain-button"} 
        src="{$Chains[1].logoURI}" 
        alt="{$Chains[1].shortName}"
      />
    </button>
    <button on:click="{() => onChainSelectClick(43114)}">
      <img class={$ChainId === 43114 ? "buttonGradient text-white chain-button buttonGradient" : "chain-button"} src="{$Chains[43114].logoURI}" alt="{$Chains[43114].shortName}"/>
    </button>
    <button on:click="{() => onChainSelectClick(56)}">
      <img class={$ChainId === 56 ? "buttonGradient text-white chain-button buttonGradient" : "chain-button"} src="{$Chains[56].logoURI}" alt="{$Chains[56].shortName}"/>
    </button>
    <button on:click="{() => onChainSelectClick(369)}">
      <img class={$ChainId === 369 ? "buttonGradient text-white chain-button buttonGradient" : "chain-button"} src="{$Chains[369].logoURI}" alt="{$Chains[369].shortName}"/>
    </button>
    <!-- {#if window.location.hostname == 'localhost'}
      <button on:click="{() => onChainSelectClick(5)}">
        <img style="{$ChainId === 5 ? `background: linear-gradient(90deg, #${buttonGradient} 0%, ${hexToRgba(buttonGradient, 0.5)} 100%);` : ''}" 
        class={$ChainId === 5 ? "buttonGradient text-white chain-button" : "chain-button"} src="{$Chains[5].logoURI}" alt="{$Chains[5].shortName}"/>
      </button>
    {/if} -->
  </div>
  <span class="infoText pt-4 italic">{'Language'}</span>
  <div class="grid grid-cols-4 gap-4 mt-2">
      <div class="flex flex-col items-center justify-center">
        <button class={$Locale == 'en' ? "buttonGradient text-white chain-button" : "chain-button"} on:click={(_) => onLocaleClick('en')}>
            <span class="fi fi-gb"></span>
        </button>
        <span class="infoText text-xs pt-1">EN</span>
      </div>

      <div class="flex flex-col items-center justify-center">
          <button class={$Locale == 'es' ? "buttonGradient text-white chain-button" : "chain-button"} on:click={(_) => onLocaleClick('es')}>
              <span class="fi fi-es"></span>
          </button>
        <span class="infoText text-xs pt-1">ES</span>
      </div>

      <div class="flex flex-col items-center justify-center">
        <button class={$Locale == 'cz' ? "buttonGradient text-white chain-button" : "chain-button"} on:click={(_) => onLocaleClick('cz')}>
            <span class="fi fi-cz"></span>          
        </button>
        <span class="infoText text-xs pt-1">CZ</span>
      </div>

      <div class="flex flex-col items-center justify-center">
        <button class={$Locale == 'pt' ? "buttonGradient text-white chain-button" : "chain-button"} on:click={(_) => onLocaleClick('pt')}>
            <span class="fi fi-br"></span>          
        </button>
        <span class="infoText text-xs pt-1">PT</span>
      </div>

      <div class="flex flex-col items-center justify-center">
        <button class={$Locale == 'bn' ? "buttonGradient text-white chain-button" : "chain-button"} on:click={(_) => onLocaleClick('bn')}>
            <span class="fi fi-bd"></span>          
        </button>
        <span class="infoText text-xs pt-1">BN</span>
      </div>


    <!-- {#if window.location.hostname == 'localhost'}
      <button on:click="{() => onChainSelectClick(5)}">
        <img style="{$ChainId === 5 ? `background: linear-gradient(90deg, #${buttonGradient} 0%, ${hexToRgba(buttonGradient, 0.5)} 100%);` : ''}" 
        class={$ChainId === 5 ? "buttonGradient text-white chain-button" : "chain-button"} src="{$Chains[5].logoURI}" alt="{$Chains[5].shortName}"/>
      </button>
    {/if} -->
  </div>
  <!-- <span class="pt-4 text-black font-extralight text-lg">Something</span>
  <div>
    <input class="numeric-input font-extralight max-w-[3em] text-right pr-2" placeholder="10">
    <span class="font-extralight">minutes</span>
  </div> -->
  <div class="switches-container pt-4">
    <div class="flex">
      <span class="text-sm infoText">{!$USER_SETTINGS.providerMode ? SkipTexts[$Locale].show_inactive_trades : "Show inactive liq"}</span>
        <SwitchComponent bind:checked="{switchValueInactiveTrades}" design="slider" label=""></SwitchComponent>
    </div>
    <div class="flex pt-2">
      <span class="text-sm infoText">{SkipTexts[$Locale].provider_mode}</span>
        <SwitchComponent bind:checked="{$USER_SETTINGS.providerMode}" design="slider" label=""></SwitchComponent>
    </div>
    <LoadBlaster showLoad={loading}/>
    {#if userInfo && collateralToken && BigNumber(userInfo.collateral).gt(0) && +userInfo.tradeLocked == 0}
      <div class="flex flex-col pt-2">
        <button on:click={onDecollateralizeClick} class="p-4 {+userInfo.tradeLocked != 0 ? 'bg-gray-500' : 'buttonGradient'} flex flex-col rounded-lg">
          <span class="text-white">{SkipTexts[$Locale].decollateralize}</span>
          <span class="text-white text-xs">{BigNumber(userInfo?.collateral).div(10**(collateralToken?.decimals || 18))} {collateralToken.symbol}</span>
        </button>
      </div>
    {/if}
    <!-- <button 
      on:click={fixRpc} 
      style="background: linear-gradient(90deg, #{buttonGradient} 0%, {hexToRgba(buttonGradient, 0.5)} 100%);" 
      class="rpc-button">Fix my RPC
    </button> -->
  </div>
</main>

<style lang="scss">
  .chain-button{
    @apply flex flex-col items-center justify-center;
    width: 3em;
    border-radius: 16px;
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--border-color);
  }

  .settings-container{
    position: absolute;
    z-index: 5;
    border-radius: 16px;
    padding: 16px 16px 16px 16px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.01), 0px 8px 4px 0px rgba(0, 0, 0, 0.04), 0px 24px 16px 0px rgba(0, 0, 0, 0.04), 0px 32px 24px 0px rgba(0, 0, 0, 0.01);
  }
</style>